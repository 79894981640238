<script lang="ts">
  import NewContentButtons from './new-content-buttons.svelte'
</script>

<div class="flex flex-col w-full h-full min-h-[96vh] items-center justify-center">
  <div class="new-contents auto-grid m-auto">
    <NewContentButtons />
  </div>
</div>

<style lang="postcss">
  .auto-grid {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 1rem;
    width: fit-content;
    justify-content: space-between;
  }
</style>
